.dropdown.configDropdown {
  margin-left: 20px;

  button {
    padding: 0;
    height: 31px;
    color: #dee2e6;

    &:hover {
      color: silver;
    }
  }

  &.show {
    button {
      color: #5cb85c;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {

    &:active,
    &:hover {
      color: inherit;
      background: none;
    }

    label {
      display: block;
      cursor: pointer;
    }
  }
}
