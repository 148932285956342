.App-header {
  padding: 40px 15px;
  text-align: center;
  position: relative;
}

#spotifyErrorMessage {
  text-align: center;
}

#loginButton {
  display: block;
  margin: 0 auto;
}

#topMenu {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 0;

  button {
    color: #dee2e6;
    padding: 10px 6px;

    &:hover {
      color: silver;
    }
  }
}

#languageDropdown {
  .dropdown-item svg {
    &.selected {
      color: #5cb85c;
    }

    &:not(.selected) {
      opacity: 0.1;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#playlists {
  animation: fadeIn 1s;

  table {
    thead {
      th {
        border-top-width: 0;

        &.icon {
          width: 30px;
        }

        &.owner {
          width: 150px;
        }

        &.tracks {
          width: 100px;
        }

        &.public,
        &.collaborative {
          width: 120px;
        }

        &.export {
          width: 100px;
        }
      }
    }

    &.table-sm {

      td,
      th {
        padding: 8px;
      }
    }
  }
}

#playlistsHeader {
  display: flex;
  flex-direction: row-reverse;

  .paginator {
    margin-left: 20px;
  }

  .progress {
    flex-grow: 1;
    height: 30px;

    .progress-bar {
      white-space: nowrap;
      padding: 4px 10px;
      text-align: left;

      // Transitioning when resetting looks weird
      &[aria-valuenow="0"] {
        transition: none;
      }
    }
  }

  form {
    margin-left: 20px;
  }
}

#playlistsFooter {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.spinner {
  min-width: 24px;
  min-height: 24px;
}

.spinner:before {
  content: 'Loading…';
  position: absolute;
  top: 240px;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
}

.spinner:not(:required):before {
  content: '';
  border-radius: 50%;
  border: 4px solid rgba(236, 235, 232, 1);
  border-top-color: rgba(130, 130, 130, 1);
  animation: spinner 1s linear infinite;
  -webkit-animation: spinner 1s linear infinite;
}

.ribbon {
  background-color: #84BD00;
  overflow: hidden;
  white-space: nowrap;
  /* top left corner */
  position: absolute;
  left: -50px;
  top: 40px;
  /* 45 deg ccw rotation */
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  /* shadow */
  -webkit-box-shadow: 0 0 10px #888;
  -moz-box-shadow: 0 0 10px #888;
  box-shadow: 0 0 10px #888;
}

.ribbon a {
  border: 1px solid #ded;
  color: #fff;
  display: block;
  font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  /* shadow */
  text-shadow: 0 0 5px #444;
}
